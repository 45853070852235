<template>
    <div>
        <top title="修改手机号码" />
        <BackgroundBox :box_title="'修改手机号码'" :btn="isOld?'下一步':'确定'" :nullFlag="nullFlag" @Onsubmit="Onsubmit">
            <template v-slot:headers>
                <!-- <UserPhoneField v-model="formData.oldPhone"  :disabled="true" :phText="'请输入原手机号码'" /> -->
                <UserPhoneField :inText="formData.oldPhone" :type="false"  v-if="isOld" />
                <CodeField v-model="formData.oldCode" :phText="'请输入原手机号码验证码'" v-if="isOld" ref="codeFieldNew" @onCodeClick="onOldCodeClick"  />
                <UserPhoneField v-model="formData.newPhone" :phText="'请输入新手机号码'" v-show="!isOld"  />
                <CodeField v-model="formData.newCode" :phText="'请输入新手机号码验证码'" v-show="!isOld" ref="codeFieldOld" @onCodeClick="onNewCodeClick" />
            </template>
        </BackgroundBox>
    </div>
</template>

<script>
import BackgroundBox from './components/box'
import UserPhoneField from './components/userphone'
import CodeField from './components/code'
import {updatePhone,oldPhone } from '@/api/_api/register'
export default {
    components:{BackgroundBox,UserPhoneField,CodeField},
    data(){
        return{
            formData:{
                oldPhone:'',
                oldCode:'',
                newPhone:'',
                newCode:'',
                name:''
            },
            isOld:true
        }
    },
    mounted(){
        let { username } = JSON.parse(localStorage.getItem('user_data'))
        this.formData.name = username.split('-')[0];
        this.formData.oldPhone = username.split('-')[1];
    },
    computed:{
        nullFlag(){
            if(this.isOld){
                if(this.formData.oldPhone&&this.formData.oldCode){
                    return false
                }
            }else{
                if(this.formData.newPhone&&this.formData.newCode){
                    return false
                }
            }
            return true
        }
    },
    methods:{
        Onsubmit(){
            if(this.isOld){
                let params = {
                    name:this.formData.name,
                    oldPhone:this.formData.oldPhone,
                    oldCode:this.formData.oldCode
                }
                oldPhone(params).then(res=>{
                    if(res.code == 200){
                        this.isOld = false
                    }
                })
            }else{
                updatePhone(this.formData).then(res=>{
                    if(res.code == 200){
                        this.$cookies.remove('token')
                        this.$router.push({path:'/'})
                        this.$toast.success('修改成功，请重新登录')
                    }
                })
            }
            
        },
        onNewCodeClick(){
            if(!this.formData.newPhone){
                this.$toast('请填写正确的手机号')
            }else{
                let parms = {
                    name:this.formData.name,
                    phone:this.formData.newPhone
                }
                this.$refs.codeFieldOld.init(parms,'5');
            }
        },
        onOldCodeClick(){
            if(!this.formData.oldPhone){
                this.$toast('请填写正确的手机号')
            }else{
                let parms = {
                    name:this.formData.name,
                    phone:this.formData.oldPhone
                }
                this.$refs.codeFieldNew.init(parms,'4');
            }
        }
    }
}
</script>

<style>

</style>